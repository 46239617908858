import React from 'react';
import Layout from '../components/layout';
import Form from '../components/form';
import Seo from '../components/seo';
import Notice from '../components/notice';
// import Announcement from "../components/announcement"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export const query = graphql`
    query {
        page: contentfulHomePage(homeId: {eq: "homepage"}) {
            id
            title
            quoteAuthor
            metaTitle
            metaDescription {
                metaDescription
            }
            announcement {
                raw
            }
            quoteText {
                quoteText
            }
            content {
                raw
            }
        }
    }
`;

function Home({ data: { page } }) {
    return (
        <Layout>
            <Seo
                title={page.metaTitle}
                description={page.metaDescription.metaDescription}
            />

            <div className="wrapper padding">
                <section className="section section-intro home">
                    <div className="container">
                        <div className="col12">
                            <Notice />

                            <h1>{page.title}</h1>
                        </div>
                    </div>
                </section>

                <div className="section section-quote">
                    <div className="container">
                        <div className="col12">
                            <div className="quote-box">
                                <blockquote className="quote">
                                    <span className="quote-sign">“</span>
                                    <p className="quote-text">{page.quoteText.quoteText}</p>
                                    <p className="quote-author">{page.quoteAuthor}</p>
                                </blockquote>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="section section-faq home">
                    <div className="container">
                        <div className="col12">
                            <div className="faq">
                                <div className="text-block">
                                    <div className="block-section">
                                        {page.content ? documentToReactComponents(page.content.json) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Form />
            </div>
        </Layout>
    );
}

export default Home
