import React, { Component } from 'react';

class Notice extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         isHidden: false
    //     }
    // }

    // closeNotice = () => {
    //     if (typeof window !== 'undefined') {
    //         localStorage.setItem('notice', 'notice-closed');
    //         this.setState({isHidden: true})
    //     }
    // }

    render() {
        return (
            <div className={`notice notice-covid`}>
                {/* <button className="notice-close" onClick={this.closeNotice}>Close</button> */}
                <div className="notice-cont">
                    <div className="notice-img"></div>
                    <h3>Coronavirus (COVID-19) disclaimer:</h3>
                    <p>In light of the current government recommendations to delay the spread of COVID-19, I am now conducting all my therapy sessions via Skype/Zoom or by telephone consultations.</p>
                </div>
            </div>
        )
    }
}

export default Notice
